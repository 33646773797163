<template>
  <v-container
    fluid
    fill-height
  >
    <v-row>
      <v-col
        cols="3"
      >
        <TemplateCategories
          :actual-tab="actualTab"
        />
      </v-col>
      <v-col
        cols="9"
      >
        <TemplatesGeneralOverview
          @tabWasSelected="onTabChanged"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TemplateCategories from './TemplateCategories.vue'
import TemplatesGeneralOverview from './TemplatesGeneralOverview.vue'

export default {
  components: { TemplateCategories, TemplatesGeneralOverview },
  data () {
    return {
      lavviraTab: false,
      actualTab: null
    }
  },
  methods: {
    onTabChanged (emittedTab) {
      this.actualTab = emittedTab
      if (emittedTab.isLavviraTab) {
        this.lavviraTab = true
        return this.lavviraTab
      } else {
        this.lavviraTab = false
        return this.lavviraTab
      }
    }
  }
}
</script>
